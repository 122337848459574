<template>
    <div>
        <el-row>
            <el-col>
                <el-autocomplete
                    class="inline-input"
                    v-model="item"
                    :value-key="valueKey"
                    :placeholder="placeholder"
                    :fetch-suggestions="fetchSuggestions"
                    :trigger-on-focus="false"
                    :disabled="disabled"
                    @select="addItem"/>

            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <div v-for="(item, key) in items" :key="key" class="item mt-3">
                    <i class="el-icon-close icon-gray" @click="removeItem(item)"></i> {{item[valueKey]}}
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "AutocompleteMultiSelector",
        props: {
            value: Array,
            placeholder: String,
            suggestions: Function,
            valueKey: {
                type: String,
                default: 'value'
            },
            disabled: Boolean
        },
        data() {
            return {
                item: null,
                items: []
            }
        },
        mounted() {
            this.items = this.value;
        },
        methods: {
            fetchSuggestions(query, cb) {
                this.suggestions(query, (suggestions) =>
                    cb(suggestions.filter((suggestion) =>
                        !this.items.some((item) =>
                            item[this.valueKey] === suggestion[this.valueKey]))));
            },
            addItem(item) {
                if (item) {
                    this.items.push(item);
                    this.item = null;
                    this.notifyChange(this.items);
                }
            },
            removeItem(value) {
                this.items = this.items.filter(item => item !== value);
                this.notifyChange(this.items);
            },
            notifyChange(items) {
                this.$emit('input', items);
            }
        },
        watch: {
            value: function () {
                this.items = this.value;
            },
        }
    }
</script>

<style scoped>
    .item {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    i {
        cursor: pointer;
    }

    i.el-icon-circle-plus {
        font-size: 20px;
    }
</style>
