<template>
    <autocomplete-multi-selector
        :disabled="cakeshopIds.length === 0"
        v-model="selectedItems"
        placeholder="Buscar Tartas"
        value-key="name"
        :suggestions="suggestions"/>
</template>

<script>
    import AutocompleteMultiSelector from "../AutocompleteMultiSelector/index";
    import {getCakes} from '@/api/cake-api';
    export default {
        name: "CakeAutoMultiSelector",
        components: {AutocompleteMultiSelector},
        props: {
            value: Array,
            cakeshopIds: Array,
        },
        data() {
            return {
                cakes: [],
            }
        },
        computed: {
            selectedItems: {
                get: function() {
                    return this.value;
                },
                set: function(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            this.loadCakes();
        },
        methods: {
            suggestions(query, cb) {
                if (query == null) cb([]);
                let results = query
                    ? this.cakes.filter(cake => cake.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                    : [];
                cb(results);
            },
            loadCakes() {
                this.cakes = [];
                this.cakeshopIds.forEach(cakeshopId =>
                    getCakes(cakeshopId).then(cakes => this.cakes = this.cakes.concat(cakes)));
            }
        },
        watch: {
            cakeshopIds: function () {
                this.loadCakes();
            },
        }
    }
</script>