<template>
    <autocomplete-multi-selector
        v-model="selectedItems"
        placeholder="Buscar Ciudades"
        value-key="name"
        :suggestions="suggestions"/>
</template>

<script>
    import AutocompleteMultiSelector from "../AutocompleteMultiSelector/index";
    import {mapGetters} from 'vuex';
    export default {
        name: "CityAutoMultiSelector",
        components: {AutocompleteMultiSelector},
        props: {
            value: Array,
        },
        computed: {
            ...mapGetters([
                'cities'
            ]),
            selectedItems: {
                get: function() {
                    return this.value;
                },
                set: function(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            this.$store.dispatch('loadCities');
        },
        methods: {
            suggestions(query, cb) {
                if (query == null) cb([]);
                let results = query
                    ? this.cities.filter((city) => city.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                    : [];
                cb(results);
            },
        }
    }
</script>