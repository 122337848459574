<template>
    <el-select
        v-model="label"
        placeholder="Selecciona Campaña"
        @change="notifyChange">
        <el-option
            v-for="option in typesOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value">
        </el-option>
    </el-select>
</template>

<script>
    import {campaignType} from '../../../constants/campaign-constants';

    export default {
        name: "CampaignTypeSelector",
        props: {
            value: String
        },
        data() {
            return {
                label: null
            }
        },
        created() {
            this.label = this.evaluateValue(this.value);
        },
        computed: {
            typesOptions() {
                return Object.keys(campaignType).map((key) => {
                    return {
                        value: key,
                        label: campaignType[key]
                    };
                });
            }
        },
        methods: {
            evaluateValue(value) {
                if (value) {
                    let type = this.findTypeByValue(value);
                    return type ? type.label : null;
                } else {
                    return null;
                }
            },
            findTypeByValue(value) {
                return this.typesOptions.find(option => (option.value === value));
            },
            notifyChange(type) {
                this.$emit('input', type);
                this.$emit('change');
            }
        },
        watch: {
            value: function () {
                this.label = this.evaluateValue(this.value);
            }
        }
    }
</script>