import request from '@/utils/request'

export function getCakes(cakeshopid) {
    return request.get('/cakeshops/' + cakeshopid + '/cakes?purchasable=false')
}

export function activateCake(uuid) {
    return changeCakeStatus(uuid, 'PUBLISHED')
}

export function disableCake(uuid) {
    return changeCakeStatus(uuid, 'INACTIVE')
}

function changeCakeStatus(uuid, status) {
    return request.post('/cakes/' + uuid + '/status', {
        status: status
    });
}

export function newCake(cakeshopId, data) {
    const url = '/cakeshops/' + cakeshopId + '/cakes';
    return request.post(url, data);
}

export function updateCake(uuid, data) {
    return request.post('/cakes/' + uuid, data);
}

export function setCakePrices(cakeId, data) {
    const url = '/cakes/' + cakeId + '/pricing';
    return request.post(url, data);
}

export function getCake(cakeId) {
    return request.get('/cakes/' + cakeId)
}

export function deleteCake(uuid) {
    return request.delete('/cakes/' + uuid);
}
