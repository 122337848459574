<template>
    <el-form
        ref="campaignForm"
        :model="campaign"
        :rules="rules"
        size="medium"
        :status-icon="true"
        :inline-message="false"
        :hide-required-asterisk="true"
        label-position="top"
        @submit.prevent>
        <el-row class="mt-2" :gutter="20">

            <el-col :span="9">

                <el-row>
                    <el-col>
                        <el-form-item prop="name">
                            <template slot="label">
                                Nombre de Campaña*
                            </template>
                            <el-input
                                v-model="campaign.name"
                                placeholder="Nombre"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="16">
                        <el-form-item prop="description">
                            <template slot="label">
                                Descripción*
                            </template>
                            <el-input
                                v-model="campaign.description"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                maxlength="256"
                                placeholder="Máx 256 caracteres"
                                class="max-width">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="startDate">
                            <template slot="label">
                                Fecha de Inicio*
                            </template>
                            <el-date-picker
                                format="dd/MM/yyyy"
                                :clearable="false"
                                v-model="campaign.startDate"
                                :picker-options="startDateOptions"
                                type="date"
                                placeholder="Selecciona Fecha">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="endDate">
                            <template slot="label">
                                Fecha de Finalización*
                            </template>
                            <el-date-picker
                                format="dd/MM/yyyy"
                                :clearable="false"
                                v-model="campaign.endDate"
                                type="date"
                                :disabled="campaign.startDate == null"
                                :picker-options="endDateOptions"
                                placeholder="Selecciona Fecha">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <el-switch v-model="campaign.useWithFavorite"/>
                        <span class="switch-label ml-2">Disponible como favorita</span>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :span="8">

                <el-row>
                    <el-col>
                        <el-form-item prop="type">
                            <template slot="label">
                                Tipo de Campaña*
                            </template>
                            <campaign-type-selector v-model="campaign.type" @change="resetType"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <span v-if="showCampaignConfig">

                    <el-row>
                        <el-col>
                            <el-form-item prop="code">
                                <template slot="label">
                                    Nombre Código
                                </template>
                                <el-input
                                    v-model="campaign.code"
                                    class="max-width"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row v-if="moneyDiscount || percentageDiscount">
                        <el-col :span="12">
                            <el-form-item prop="discount">
                                <template slot="label">
                                    Cuantía Descuento {{moneyDiscount ? '€' : '%'}}
                                </template>
                                <el-input
                                    v-model.number="campaign.discount"
                                    :maxlength="percentageDiscount ? 3 : null"
                                    class="max-width"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row class="mt-4">
                        <el-col>
                            <el-switch v-model="campaign.usesLimit" @change="resetUsesLimit"/>
                            <span class="switch-label ml-2">Limitar Usos</span>
                        </el-col>
                    </el-row>

                    <span v-if="campaign.usesLimit">

                        <el-row class="mt-3">
                            <el-col :span="12">
                                <el-form-item prop="totalUses">
                                    <template slot="label">
                                        Nº Usos totales
                                    </template>
                                    <el-input
                                        v-model.number="campaign.totalUses"
                                        class="max-width"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="12">
                                <el-form-item prop="usesPerPerson">
                                    <template slot="label">
                                        Nº Usos por usuario
                                    </template>
                                    <el-input
                                        v-model.number="campaign.usesPerPerson"
                                        class="max-width"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </span>

                </span>

            </el-col>

            <el-col :span="6">
                <el-row class="mt-4">
                    <el-col>
                        <el-switch v-model="campaign.scopeLimit" @change="resetScopeLimit"/>
                        <span class="switch-label ml-2">Limitar alcance</span>
                    </el-col>
                </el-row>

                <div v-if="campaign.scopeLimit">

                    <el-row class="mt-4">
                        <el-col>
                            <el-form-item prop="cities">
                                <template slot="label">
                                    Listado de Ciudades
                                </template>
                                <city-auto-multi-selector v-model="campaign.cities"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col>
                            <el-form-item prop="tags">
                                <template slot="label">
                                    Listado de Tags
                                </template>
                                <tag-auto-multi-selector v-model="campaign.tags"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col>
                            <el-form-item prop="tags">
                                <template slot="label">
                                    Listado de Pastelerías
                                </template>
                                <cakeshop-auto-multi-selector v-model="campaign.cakeshops" @change="resetCakes"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col>
                            <el-form-item prop="tags">
                                <template slot="label">
                                    Listado de Tartas
                                </template>
                                <cake-auto-multi-selector
                                    :cakeshop-ids="campaign.cakeshops.map(cakeshop => cakeshop.uuid)"
                                    v-model="campaign.cakes"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </div>

            </el-col>

        </el-row>
    </el-form>
</template>

<script>
    import CampaignTypeSelector from "./CampaignTypeSelector";
    import {campaignType} from '../../../constants/campaign-constants';
    import CityAutoMultiSelector from "../../../components/CityAutoMultiSelector";
    import TagAutoMultiSelector from "../../../components/TagAutoMultiSelector/index";
    import CakeshopAutoMultiSelector from "../../../components/CakeshopAutoMultiSelector";
    import CakeAutoMultiSelector from "../../../components/CakeAutoMultiSelector";
    import moment from 'moment';

    export default {
        name: "CampaignForm",
        components: {
            CakeAutoMultiSelector,
            CakeshopAutoMultiSelector,
            TagAutoMultiSelector,
            CityAutoMultiSelector,
            CampaignTypeSelector,
        },
        props: {
            value: {
                type: Object,
            },
        },
        data() {

            let discountValidator = (rule, value, callback) => {
                setTimeout(() => {
                    if (!Number.isInteger(parseInt(value)) || value > 100) {
                        callback(new Error('No es un valor válido'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            let personUsesValidator = (rule, value, callback) => {
                setTimeout(() => {
                    if (this.campaign.totalUses != null && value > this.campaign.totalUses) {
                        callback(new Error('No puede ser mayor que el número de usos totales'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            return {
                campaign: {
                    name: null,
                    description: null,
                    startDate: null,
                    endDate: null,
                    type: null,
                    code: null,
                    discount: null,
                    usesLimit: false,
                    totalUses: null,
                    usesPerPerson: null,
                    useWithFavorite: false,
                    scopeLimit: false,
                    cities: [],
                    cakeshops: [],
                    cakes: [],
                    tags: [],
                },
                rules: {
                    name: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 3, message: 'Longitud mínima de 3 caracteres', trigger: 'blur'}
                    ],
                    description: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 3, max: 256, message: 'Longitud entre 3 y 256 caracteres', trigger: 'blur'}
                    ],
                    startDate: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                    ],
                    endDate: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                    ],
                    discount: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {validator: discountValidator, trigger: 'blur'},
                    ],
                    usesPerPerson: [
                        {validator: personUsesValidator, trigger: 'blur'}
                    ]
                },
                showRequiredImageError: false
            }
        },
        created() {
            this.campaign = Object.assign({}, this.campaign, this.value);
            if (this.campaign.startDate !== null) {
                this.campaign.startDate = new Date(this.campaign.startDate);
            }
            if (this.campaign.endDate !== null) {
                this.campaign.endDate = new Date(this.campaign.endDate);
            }
        },
        computed: {
            startDateOptions() {
                return {
                    disabledDate(time) {
                        return time.getTime() < (Date.now() - 86400000);
                    },
                }
            },
            endDateOptions() {
                let campaign = this.campaign;
                return {
                    disabledDate(time) {
                        return moment(time).isBefore(campaign.startDate, 'day') || time.getTime() < (Date.now() - 86400000);
                    },
                }
            },
            showCampaignConfig() {
                return this.campaign.type != null && campaignType[this.campaign.type] !== campaignType.WITHOUT_DISCOUNT
            },
            moneyDiscount() {
                return campaignType[this.campaign.type] === campaignType.MONEY_DISCOUNT;
            },
            percentageDiscount() {
                return campaignType[this.campaign.type] === campaignType.PERCENTAGE_DISCOUNT;
            }
        },
        methods: {
            async validate() {
                this.$emit('input', this.campaign);
                return await this.$refs.campaignForm.validate();
            },
            resetCakes() {
                this.campaign.cakes = this.campaign.cakes.filter(cake => this.campaign.cakeshops.some(cakeshop => cakeshop.uuid === cake.cakeshopId));
            },
            resetType() {
                this.campaign.discount = null;
                this.$refs.campaignForm.clearValidate('discount');
            },
            resetUsesLimit(value) {
                if (!value) {
                    this.campaign.totalUses = null;
                    this.campaign.usesPerPerson = null;
                }
            },
            resetScopeLimit(value) {
                if (!value) {
                    this.campaign.cities = [];
                    this.campaign.cakeshops = [];
                    this.campaign.cakes = [];
                    this.campaign.tags = [];
                }
            }
        },
        watch: {
            'campaign.description': function () {
                if (!!this.campaign.description && this.campaign.description.length > 256) {
                    this.campaign.description = this.campaign.description.substring(0, 256);
                }
            },
            'campaign.startDate': function () {
                if (moment(this.campaign.startDate).isAfter(this.campaign.endDate, 'day')) {
                    this.campaign.endDate = null;
                }
            },
        }
    }
</script>

<style scoped>
    >>> label {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .switch-label {
        font-size: 12px;
        letter-spacing: 0.02em;
    }
</style>
